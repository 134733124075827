import React from 'react';
import {Link} from 'react-router-dom';
import FooterBG from '../../assets/images/footer.png';
import { FaPhoneAlt, FaAngleDoubleRight } from 'react-icons/fa';

const Footer = () => {
    const handleSubmit = (e) => {
        e.preventDefault();

    const myForm = e.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        // setTimeout(()=>{handleNotification();}, 200);
        // setformStatus(!formStatus);
      })
      .catch((error) => alert(error));
    }

    
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2 pt-110 pb-80" style={{ backgroundImage: `url(${FooterBG})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p>If you have any questions or need help, feel free to contact with our team.</p>
                                    </div>
                                    <div className="footer-info">
                                        <Link to="/"><FaPhoneAlt /> (786) 344 7564</Link>
                                    </div>
                                    {/* <div className="footer-text">
                                        <p>8553 Miami, Florida</p>
                                    </div> */}
                                    {/* <div className="footer-info">
                                        <Link to="/"><FaPhoneAlt /> Get Directions</Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                                <div className="footer-widget-2">
                                    <div className="footer-title">
                                        <h4>Quick Links</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/contact">Contact Us</Link></li>
                                            {/* <li><Link to="/">Team</Link></li> */}
                                            <li><Link to="/services">Our Services</Link></li>
                                            {/* <li><Link to="/">Our Project</Link></li> */}
                                            <li><Link to="/about">About Company</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                                <div className="footer-widget-3">
                                    <div className="footer-title">
                                        <h4>Our Services</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/services">Comprehensive Home Inspections</Link></li>
                                            <li><Link to="/services">Commercial Property Inspectionss</Link></li>
                                            <li><Link to="/services">Pest and Termite Inspections</Link></li>
                                            <li><Link to="/services">Specialized Inspections</Link></li>
                                            <li><Link to="/services">Consultative Services</Link></li>
                                            <li><Link to="/services">Drone Roof Inspections</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-4">
                                    <div className="footer-title">
                                        <h4>News Letter</h4>
                                    </div>
                                    {/* <div className="footer-text">
                                        <p>Established since 2000. Landscaping Company in US.</p>
                                    </div> */}
                                    
                                    <form name="subscribe" className="footer-newsletter mt-40" onSubmit={handleSubmit}>
                                        <input type="text" name='subscribe_user_email' placeholder="Email Address" />
                                        <button type='submit'><FaAngleDoubleRight /></button>
                                    </form>
                                    {/* <div className="footer-social mt-35">
                                        <h5>Follow Us:</h5>
                                        <ul>
                                            <li><Link to="/"><FaFacebookF /></Link></li>
                                            <li><Link to="/"><FaTwitter /></Link></li>
                                            <li><Link to="/"><FaInstagram /></Link></li>
                                            <li><Link to="/"><FaPinterestP /></Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom bg-1 pt-40 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="copyright">
                                    <p>&copy;2024 PineTreeInspection All right reserved.</p>
                                    {/* <p>Terms & Privacy Policy</p> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-xl-end text-lg-end text-md-end text-center">
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/about">About GreenScape</Link></li>
                                        {/* <li><Link to="/">Careers</Link></li> */}
                                        <li><Link to="/privacy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;