import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowRight,
  FaHeadphones,
  FaEnvelopeOpen,
  FaClock,
} from "react-icons/fa";

const ContactForm = () => {
    const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        navigate("/");
      })
      .catch((error) => alert(error));
  };
  return (
    <>
      <div className="contact-area white-bg pt-110 pb-110">
        <div className="container">
          <div className="box-shadow-3 pt-60 pb-60 pl-80 pr-80">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="contact-form pr-70">
                  <div className="section-title mb-40">
                    <span>Need Our Services or Have Queries?</span>
                    <h2>Send Us a Message.</h2>
                  </div>
                  <form name="contact" onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                      type="text"
                      name="user_name"
                      placeholder="Your Name"
                    />
                    <input
                      type="email"
                      name="user_email"
                      placeholder="Your Email"
                    />
                    <input
                      type="text"
                      name="user_subject"
                      placeholder="Your Subject"
                    />
                    <textarea
                      name="user_message"
                      placeholder="Write Your Message Here"
                    ></textarea>
                    <button className="l-btn" type="submit">
                      Send Message <FaArrowRight />
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="contact-box bg-1 pt-60 pb-35 pl-35 pr-35 mr-40">
                  <span>Inspection Excellence!</span>
                  <h3>Comprehensive Inspection Services</h3>
                  <p>
                    Experience top-tier inspection services tailored to your
                    needs. We prioritize accuracy and professionalism in every
                    inspection.
                  </p>
                  <ul className="mt-40">
                    <li>
                      <FaHeadphones />
                      <span>
                        Emergency Line: <Link to="/">(786) 344 7564</Link>
                      </span>
                    </li>
                    <li>
                      <FaEnvelopeOpen />
                      <span>
                        Our Email:{" "}
                        <Link to="/"> info@pinetree-inspections.com </Link>
                      </span>
                    </li>
                    {/* <li><FaMapMarkerAlt /><span>Location: , State</span></li> */}
                    <li>
                      <FaClock />
                      <span>Mon - Fri: 9:00 am - 5:00 pm</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
