import React, {useEffect} from 'react';

const Privacy = () => {
      useEffect(() => {
          window.scrollTo(0, 0);
      }, []);
  return (
    <div className="policy">
      <h1 className="policy-header">PineTreeInspection Privacy Policy</h1>
      <p>
        At Pinetree Inspections LLC, we value your privacy and are committed to complying with SMS compliance requirements to ensure your data is protected. This Privacy Policy outlines how we collect, use, and safeguard the information provided when you register for SMS services related to our home inspection offerings.
      </p>
      
      <h2>1. Information Collection</h2>
      <p>
        When you register your phone number for SMS notifications from Pinetree Inspections LLC, we collect the following information:
      </p>
      <ul>
        <li>Your phone number</li>
        <li>Your consent to receive SMS communications</li>
      </ul>

      <h2>2. Use of Information</h2>
      <p>
        The information collected will be used solely for sending SMS communications related to home inspection services, including:
      </p>
      <ul>
        <li>Appointment reminders</li>
        <li>Scheduling updates</li>
        <li>Promotional offers and discounts</li>
        <li>Inspection status notifications</li>
        <li>Customer support communications</li>
      </ul>
      <p>
        Your phone number will not be shared with third parties for marketing purposes without your express consent.
      </p>

      <h2>3. Data Security</h2>
      <p>
        We take the protection of your personal data seriously. All information provided will be securely stored and only used by authorized personnel within Pinetree Inspections LLC or shared as required by law. We adhere to industry-standard practices to safeguard your data from unauthorized access, modification, or deletion.
      </p>

      <h2>4. Opt-Out Option</h2>
      <p>
        You have the option to opt-out of receiving SMS messages at any time. To stop receiving SMS notifications, simply reply "STOP" to any SMS message. Upon receiving this request, we will immediately remove your number from our SMS list.
      </p>

      <h2>5. Consent</h2>
      <p>
        By providing your phone number, you consent to receive SMS communications from Pinetree Inspections LLC related to home inspection services. You affirm that you are the authorized user of the phone number provided and that you agree to this Privacy Policy.
      </p>

      <h2>6. Changes to Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. If there are any significant changes, they will be reflected on this page. The updated Privacy Policy will apply to all information collected after the revision date.
      </p>

      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us.
      </p>
      <address>
        <strong>Pinetree Inspections LLC</strong><br/>
        57 NW 41st Ave<br/>
        Miami, Florida, 33126<br/>
        Email: <a href="mailto:info@pinetree-inspections.com">info@pinetree-inspections.com</a><br/>
        Phone: (786) 496-9908
      </address>
      <p><strong>Effective Date: 02/05/2025</strong></p>
      <p>Thank you for trusting Pinetree Inspections LLC for your home inspection needs.</p>
    </div>
  );
}

export default Privacy;
